import React from "react";

import Notification, { NotificationI } from './Notification';

export type NotifyFn = (t: NotificationI) => void;
type SetNotifyFn = (create: NotifyFn) => void;

interface NotificationDetailed extends NotificationI {
  id: number
}
interface NotificationManagerProps {
  setNotify: SetNotifyFn
};

const NotificationsManager = ({ setNotify }: NotificationManagerProps) => {
    let [notifications, setNotifications] = React.useState<NotificationDetailed[]>([]);

    React.useEffect(() => {
      setNotify(({message, error}: NotificationI) => 
        createNotification({message, error}));
    }, [setNotify]);

    function createNotification({message, error}: NotificationI) {
      setNotifications((prevNotifications) => {
        return [
          ...prevNotifications,
          {
            message,
            error,
            id: prevNotifications.length,
          },
        ];
      });
    };

    function deleteNotification(id: number) {
      const filteredNotifications = notifications.filter(
        (_, index) => id !== index,
        []
      );
      setNotifications(filteredNotifications);
    };

    return (
      <div className="notifications">
        {notifications.map(({ id, ...props }, index) => (
            <Notification
              key={id}
              onDelete={() => deleteNotification(index)}
              {...props}
            />
          ))
        } 
      </div>
    )
}

export default NotificationsManager;