import axios from 'axios';

import { BASE_URL } from './AppConfig';

const instance = axios.create({
    baseURL: BASE_URL
});

const token = localStorage.getItem('pulsenowToken');
if (token) {
    instance.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('pulsenowToken')}`;
}
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

instance.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default instance;