import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import { PulseContext } from '../../base/Context';

import { showNotification } from '../../../App';
import RespondetsImg from '../../../img/respondents.svg';

interface RespondentsStats {
    visits: number,
    started: number,
    responding: number,
    more_one_ans: number,
    completed: number
}

const Respondents: FunctionComponent = () => {
    const pulseHash = useContext(PulseContext);

    const [stats, setStats] = useState<RespondentsStats>({
        visits: 0,
        started: 0,
        responding: 0,
        more_one_ans: 0,
        completed: 0
    })
    useEffect(() => {
        getRespondentsStats();
    }, []);

    async function getRespondentsStats() {
        try {   
            const response = await axiosConfig.get<RespondentsStats>(`api/pulses/${pulseHash}/respondents`);

            setStats(response.data);
        } catch {
            showNotification('Server error', true);
        }
    }

    return (
        <div className="respondents col-12 col-m-12 col-s-12">
            <div className="header col-auto col-m-auto col-s-auto aligned-left">
                <img src={RespondetsImg}></img>
                <span>Respondents</span>
            </div>

            <ul className='col-12 col-m-12 col-s-12'>
                <li className='aligned-between'>
                    <span>Visits</span>
                    <span>{stats.visits}</span>
                </li>
                <li className='aligned-between'>
                    <span>Started</span>
                    <span>{stats.started}</span>
                </li>
                <li className='aligned-between'>
                    <span>Responding</span>
                    <span>{stats.responding}</span>
                </li>
                <li className='aligned-between'>
                    <span>+1 response</span>
                    <span>{stats.more_one_ans}</span>
                </li>
                <li className='aligned-between'>
                    <span>Completed</span>
                    <span>{stats.completed}</span>
                </li>
            </ul>
        </div>
    )
};

export default Respondents;