import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MainContent, MainContentHeader, Sidebar, Page } from '../../base/PageCommon';
import Preloader from '../../base/Preloader';
import { Pulse, RowPulseProps } from './PulseRow';
import { showNotification } from '../../../App';
import Table from '../common/Table';

import AddImg from '../../../img/add.svg';
import OngoingImg from '../../../img/ongoing.svg';
import PausedImg from '../../../img/paused.svg';
import SearchImg from '../../../img/search.svg';
import StatusImg from '../../../img/paused.svg';


const Pulses: FunctionComponent = () => {
    let navigate = useNavigate();
    const project = localStorage.getItem('project');
    const [isLoading, setIsLoading] = useState(true);

    const [status, setStatus] = useState(0);
    const [searchBy, setSearchBy] = useState('');
    const [tableProps, setTableProps] = useState({page: 0, perPage: 10});
    const [pulsesCnt, setPulsesCnt] = useState(0);
    const [pulsesData, setPulsesData] = useState<RowPulseProps[]>([]);

    useEffect(() => {
        getPulses();
    }, [tableProps, searchBy, status, project]);

    async function getPulses() {
        interface ResponseData {
            pulses_cnt: number,
            pulses: Pulse[]
        }
        try {
            setIsLoading(true);

            const response =  await axiosConfig.get<ResponseData>('api/pulses', {
                params: {
                    project: project,
                    page: tableProps.page,
                    perPage: tableProps.perPage,
                    searchBy: searchBy,
                    status: status
                }
            });

            setPulsesCnt(response.data.pulses_cnt);
            setPulsesData(response.data.pulses.map((pulse) => ({
                onDelete: onPulseDelete,
                ...pulse
            })));
            setIsLoading(false);
        } catch {
            showNotification('Server error', true);
        }
    }

    async function createPulse() {
        if (!project) {
            showNotification('Project is undefined', true);
            return;
        }

        try {
            const response = await axiosConfig.post<{hash: string}>('api/pulses/default', {
                project: project
            });

            navigate(`/pulses/${response.data.hash}/editor`);
        } catch {
            showNotification('Server error', true);
        }
    }

    function onStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
        setStatus(parseInt(event.target.value));
    }

    function onAddNewPulse(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        createPulse();
    };

    function onPulsesTableChange(page: number, perPage: number) {
        setTableProps({page: page, perPage: perPage});
    }

    function onPulseDelete() {
        setTableProps(prevState => ({
            ...prevState,
            page: 0
        }));
    }

    return (
        <Page pageId={"pulses"}>
            <Sidebar>
                <div className="search-filter col-12 col-m-12 col-s-12 aligned-between">
                    <div className="col-2 col-m-2 col-s-2 aligned-center">
                        <img src={SearchImg}></img>
                    </div>
                    <input className="col-10 col-m-10 col-s-10" type="text" placeholder="Search..." onChange={(event) => setSearchBy(event.target.value)}></input>
                </div>
        
                <div className="status-filter radio-btn-filter col-12 col-m-12 col-s-12">
                    <div className="header col-12 col-m-12 col-s-12 aligned-left">
                        <img src={StatusImg}></img>
                        <span>Status</span>
                    </div>
                    <div className="radio-btn-wrapper col-12 col-m-12 col-s-12 aligned-left">
                        <input type="radio" name="size" value="0" id="ongoing" checked={status == 0} onChange={onStatusChange}/>
                        <label htmlFor="ongoing" className="aligned-center">
                            <img src={OngoingImg}></img>
                            Ongoing
                        </label>
                    </div>
                    <div className="radio-btn-wrapper col-12 col-m-12 col-s-12 aligned-left">
                        <input type="radio" name="size" value="1" id="paused" checked={status == 1} onChange={onStatusChange}/>
                        <label htmlFor="paused" className="aligned-center">
                            <img src={PausedImg}></img>
                            Paused
                        </label>
                    </div>
                </div>
            </Sidebar>

            <MainContent>
                <MainContentHeader>
                    <div className="aligned-right">List of pulses</div>
                    <div className="aligned-right header-actions">
                        <a href="" className="round-btn">
                            <img src={AddImg} onClick={onAddNewPulse}></img>
                        </a>
                    </div>
                </MainContentHeader>
                
                {isLoading ? (
                    <Preloader />
                ) : (
                    pulsesData.length > 0 ? (
                        <div className="pulses-list col-12 col-m-12 col-s-12">
                            <Table
                                title='Pulses'
                                data={pulsesData}
                                page={tableProps.page}
                                perPage={tableProps.perPage}
                                rowsCnt={pulsesCnt}
                                onChange={onPulsesTableChange}
                            />
                        </div>
                    ) : (
                        <div className="no-pulses col-12 col-m-12 col-s-12 aligned-center">No pulses specified</div>
                    )
                )}
            </MainContent>
        </Page>
    );
};
export default Pulses;