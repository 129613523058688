import React from 'react';

import { APP_NAME } from '../../AppConfig';

const RouteNotFound = () => {
    return(
        <div id="not-found-page" className="col-12 col-m-12 col-s-12">
            <div className="panel">
                <div className="top col-12 col-m-12 col-s-12"></div>
            </div>

            <div className="content col-12 col-m-12 col-s-12">
                <div className="app-name">{APP_NAME}</div>
                <span className="separator"></span>
                <div className="error-id">404</div>
                <div className="error-name">Page not found</div>
            </div>
        </div>
    )
};

export default RouteNotFound;