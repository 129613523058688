import axiosConfig from './../../axiosConfig';
import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Login: FunctionComponent = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(false);

    const navigate = useNavigate();

    async function loginUser() {
        try {
            const response = await axiosConfig.post(`/api/login`, {
                username: username,
                password: password
            });

            if (response.data.token) {
                localStorage.setItem('pulsenowToken', response.data.token)
                axiosConfig.defaults.headers.common['Authorization'] = `Token ${response.data.token}`;

                navigate('/pulses', { replace: true });
            } else {
                setErrors(true);
            }
        } catch {
            setErrors(true);
        }
    }

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        loginUser();
    };

    return (
        <div className="col-12 col-m-12 col-s-12 aligned-center">
            <form id="login-form" className="auth-form col-4 col-m-6 col-s-10 animated hinge delay-fast-3 fadeInUp" onSubmit={handleSubmit}>
                {errors === true && (
                    <p className="errors">Please enter a correct username and password. Note that both fields may be case-sensitive.</p>
                )}
                <p>
                    <label htmlFor="username">Username</label>
                    <input 
                        id="username"
                        name="username" 
                        type="text" 
                        required
                        onChange={(event) => setUsername(event.target.value)} 
                    />
                </p>
                <p>
                    <label htmlFor="password">Password</label>
                    <input 
                        id="password"
                        name="password" 
                        type="password"
                        required
                        onChange={(event) => setPassword(event.target.value)} 
                    />
                </p>
                <button type="submit" className="col-12 col-m-12 col-m-12">LOG IN</button>
            </form>
        </div>
    );
};

export default Login;