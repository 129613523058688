import React, { FunctionComponent } from 'react';

import Dropdown from '../common/Dropdown';


export interface Language {
    value: string,
    label: string
}
export interface LanguageOptions {
    language: string
}
interface LanguageSettingsProps {
    settingsOptions: LanguageOptions,
    languages: Language[], 
    onChange: (options: LanguageOptions) => void
}

const LanguageSettings: FunctionComponent<LanguageSettingsProps> = ({settingsOptions, languages, onChange}) => {
    function onLanguageChange(value: string) {
        onChange({ language: value });
    }

    return (
        <div className="dropdown-wrapper col-5 col-m-7 col-s-12">
            <Dropdown 
                label="" 
                value={settingsOptions.language} 
                options={languages} 
                onChange={onLanguageChange} 
            />
        </div>
    )
}


export default LanguageSettings;