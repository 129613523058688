import React from "react";

const Preloader = ({className}: {className?: string}) => {
    return (
        <div className={(className ? className : "preloader") + "-wrapper col-12 col-m-12 col-s-12 aligned-center"}>
            <div className="preloader"></div> 
        </div>
    )
}

export default Preloader;