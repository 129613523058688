import debounce from 'lodash.debounce'; 
import React, { FunctionComponent, useCallback, useContext, useEffect, useState, useRef } from "react";

import { HAS_ONE_EDGE_ERROR } from './GraphUtils';
import { OptionI, addOption, deleteOption, modifyOption } from './Utils';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import DeleteImg from '../../../img/delete.svg';
import OptionImg from '../../../img/option.svg'


export interface OptionProps {
    index: string,
    option?: OptionI,
    onDelete?: () => void,
    onAdd?: (optionText: string) => void,
    onModify?: (optionText: string, optionId: number) => void
}
export interface OptionsProps {
    questionId: number,
    showTemplate: boolean,
    _options: OptionI[];
}

const Option: FunctionComponent<OptionProps> = ({index, option, onDelete, onAdd, onModify }) => {
    const isInitialMount = useRef(true);
    const [optionText, setOptionText] = useState(option ? option.text : '');

    const debouncedSave = useCallback(
        debounce(async (newOptionText: string, optionId: number) => {
            if (onModify)
                await onModify(newOptionText, optionId);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (option) {
            if (isInitialMount.current)
                isInitialMount.current = false;
            else
                debouncedSave(optionText, option.id);
        }
    }, [optionText, debouncedSave]);

    function onClickDelete(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        if (onDelete) onDelete();
    }

    function onKeyUp(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (event.key === 'Enter') {
            if (option == undefined && onAdd) {
                showNotification('Saving');
                onAdd(optionText);

                setOptionText('');
            }
        }
    }

    return (
        <div className="option col-12 col-m-12 col-s-12 aligned-center"> 
            <div className="option-index col-2 col-m-2 col-s-12 aligned-left">
                <img src={OptionImg}></img>
                {option ? 'Option ' + index : 'New option'}
            </div>

            <div className="col-9 col-m-9 col-s-10">
                <textarea 
                    id="option-text"
                    className="col-12 col-m-12 col-s-12"
                    placeholder="Suggest an answer..."
                    value={optionText}
                    onKeyUp={onKeyUp}
                    onChange={(e) => {setOptionText(e.target.value)}}>    
                </textarea>
            </div>

            <div className="option-actions col-1 col-m-1 col-s-2">
                {option && (
                    <div className="col-12 col-m-12 col-s-12 aligned-center">
                        <a href="" className="round-btn">
                            <img src={DeleteImg} onClick={onClickDelete} />
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}

const Options: FunctionComponent<OptionsProps> = ({ questionId, showTemplate, _options }) => {
    const pulseHash = useContext(PulseContext);

    const [options, setOptions] = useState<OptionI[]>(_options);

    async function onAddOption(optionText: string) {
        const newOption = await addOption(pulseHash, optionText, questionId);
        if (newOption) 
            setOptions((prevState) => [...prevState, newOption]);
    }

    async function onDeleteOption(optionId: number) {
        if (options.length <= 1) {
            showNotification(HAS_ONE_EDGE_ERROR, true)
            return;
        }

        if (await deleteOption(pulseHash, optionId)) 
            setOptions(options.filter(option => option.id != optionId));
    }

    async function onModifyOption(optionText: string, optionId: number) {
        modifyOption(pulseHash, optionId, optionText);
    }

    return (
        <div className="options col-12 col-m-12 col-s-11">
            {options.map((option, optionIndx) => {
                return <Option
                    key={'option-' + option.id}
                    index={String.fromCharCode(optionIndx + 65)}
                    option={option}
                    onDelete={() => onDeleteOption(option.id)}
                    onModify={onModifyOption} 
                />
            })}
            {showTemplate && (
                <Option 
                    key='option-template'
                    index={''}
                    onAdd={onAddOption}
                />
            )}
        </div>
    )
}

export default Options;