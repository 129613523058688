import React, { FunctionComponent } from "react";

export interface OptionProps {
    index?: string,
    text: string,
    responseVal: number
    predictionVal?: number
}

export const Option: FunctionComponent<OptionProps> = (props) => {
    const predAvailable = props.predictionVal != null ? true : false;
    return (
        <div className={"option col-12 col-m-12 col-s-12 " + (predAvailable ? "wide" : "narrow")}>
            <div className="option-index col-1 col-m-1 col-s-12 aligned-center">{props.index}</div>

            <div className="option-content col-11 col-m-11 col-s-12 aligned-center">
                <div className="responses col-12 col-m-12 col-s-12">
                    <div className="title col-4 col-m-4 col-s-4 aligned-left">
                        <p>{props.text}</p>
                    </div>
                    <div className="progress-bar col-6 col-m-6 col-s-6 aligned-left">
                        <span style={{width: props.responseVal + '%'}}></span>
                    </div>
                    <div className="percentage col-2 col-m-2 col-s-2 aligned-center">{props.responseVal}%</div>
                </div>
                {predAvailable && (
                    <div className="predictions col-12 col-m-12 col-s-12">
                        <div className="title col-4 col-m-4 col-s-4 aligned-left">Predictions</div>
                        <div className="progress-bar col-6 col-m-6 col-s-6 aligned-left">
                            <span style={{width: props.predictionVal + '%'}}></span>
                        </div>
                        <div className="percentage col-2 col-m-2 col-s-2 aligned-center">{props.predictionVal}%</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export const RowOption: FunctionComponent<OptionProps> = (props) => {
    return(
        <tr className="option-row col-12 col-m-12 col-s-12">
            <td className="option-index col-1 col-m-1 col-s-12 aligned-left">Answer {props.index}</td>
            <td className="option-text col-4 col-m-4 col-s-8 aligned-right">
                <span className="col-12 col-m-12 col-s-12 aligned-left">{props.text}</span>
                { props.predictionVal != null && (
                    <span className="prediction col-12 col-m-12 col-s-12 aligned-left">Prediction {props.index}</span> 
                )}
            </td>
            <td className="percentage col-1 col-m-1 col-s-4 aligned-center">
                <span className="col-12 col-m-12 col-s-12 aligned-center">{props.responseVal}%</span>
                { props.predictionVal != null && (
                    <span className="prediction col-12 col-m-12 col-s-12 aligned-center">{props.predictionVal}%</span> 
                )}
            </td>
            <td className="progress-bar col-6 col-m-6 col-s-12 aligned-right">
                <div className="col-12 col-m-12 col-s-12 aligned-left">
                    <span style={{width: props.responseVal + '%'}}></span>
                </div>
                { props.predictionVal != null && (
                    <div className="prediction col-12 col-m-12 col-s-12 aligned-left">
                        <span style={{width: props.predictionVal + '%'}}></span>
                    </div> 
                )}
            </td>
        </tr>
    )
}