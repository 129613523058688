import React, { FunctionComponent } from 'react';

import CloseImg from '../../../img/close.svg'


interface MessageDialogProps {
    children: React.ReactNode[],
    onClose: () => void
}

const MessageDialog:FunctionComponent<MessageDialogProps> = ({children, onClose}) => {
    return (
        <div id="dialog-message" className="popup aligned-center">
            <div>
                <div className="title col-12 col-m-12 col-s-12 aligned-between">
                    <span className="col-auto col-m-auto col-s-auto">CONFIRM</span>
                    <button className="col-auto col-m-auto col-s-auto">
                        <img src={CloseImg} onClick={onClose}></img>
                    </button>
                </div>
                <span className="separator col-12 col-m-12 col-s-12"></span>
                <div className="content col-12 col-m-12 col-s-12 aligned-center">
                    {children[0]}
                </div>
                <span className="separator col-12 col-m-12 col-s-12"></span>
                <div className="action col-12 col-m-12 col-s-12 aligned-center">
                    {children[1]}
                </div>
            </div>
        </div>
    );
}

export default MessageDialog;