import React, { FunctionComponent } from 'react';

import Login from './Login';

const LoginPage: FunctionComponent = () => {
    return (
        <div id="login-page" className="col-12 col-m-12 col-s-12">
            <Login/>
        </div>
    );
}

export default LoginPage;