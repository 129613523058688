import debounce from 'lodash.debounce';
import React, { FunctionComponent, useCallback, useEffect, useState, useRef } from "react";
import { EdgeData, NodeData } from 'reaflow';

import AddImg from '../../../img/add.svg';
import DeleteImg from '../../../img/delete2.svg';
import CloseImg from '../../../img/close.svg';


export interface Selection {
    type: 'node' | 'edge',
    source: NodeData | EdgeData
}
interface EditorContainerProps {
    selection: Selection,
    onAdd: () => void,
    onDelete: () => void,
    onClose: () => void,
    onModify: (selectionText: string, selection: Selection) => void
}

export const EditorContainer: FunctionComponent<EditorContainerProps> = ({selection, onAdd, onClose, onDelete, onModify}) => {
    const isInitialMount = useRef(true);
    const [selectionText, setSelectionText] = useState(selection.source.text);

    const debouncedSave = useCallback(
        debounce(async (newSelectionText: string, selection: Selection) => {
            await onModify(newSelectionText, selection);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (isInitialMount.current)
            isInitialMount.current = false;
        else
            debouncedSave(selectionText, selection);
    }, [selectionText, debouncedSave]);

    useEffect(() => {
        isInitialMount.current = true;
        setSelectionText(selection.source.text);
    }, [selection])

    return  (
        <div className="element-editor-container aligned-between col-12 col-m-12 col-s-12">
            <div className="col-10 col-m-10 col-s-10 aligned-left">
                {selection.type == 'node' ? (
                    <div className="header col-auto">Question</div>
                ) : (
                    <div className="header col-auto">Option</div>
                )}

                <textarea
                    id="element-text"
                    className="col-12 col-m-12 col-s-12"
                    value={selectionText}
                    onChange={(e) => setSelectionText(e.target.value)}>
                </textarea>
            </div>
            <div className="actions aligned-center">
                <button className="round-btn">
                    <img src={CloseImg} onClick={onClose}></img>
                </button>
                <span className="separator"></span>

                {selection.type == 'node' && (
                    <button className="round-btn">
                        <img src={AddImg} onClick={onAdd} />
                    </button>
                )}

                <button className="round-btn">
                    <img src={DeleteImg} onClick={onDelete} />
                </button>
            </div>
        </div>
    )
}