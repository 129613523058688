import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AdvancedOptionsPage from './AdvancedOptions';
import DescriptionPage from './Description';
import EditorSidebar from './EditorSidebar';
import QuestionsAnswers from './QuestionsAnswers';
import RouteNotFound from '../../base/RouteNotFound';
import { PulseContext } from '../../base/Context';
import { Page } from '../../base/PageCommon';


const Editor: FunctionComponent = () => {
    const {hash} = useParams();

    const [displayPage, setDisplyPage] = useState(0);
    useEffect(() => {

    }, [displayPage]);

    function onDisplayPageChange(newDisplayPage: number) {
        setDisplyPage(newDisplayPage);
    }

    return (hash ? (
        <PulseContext.Provider value={hash}>
            <Page pageId={"editor"}>

                <EditorSidebar displayPage={displayPage} onDisplayPageChange={onDisplayPageChange} />

                { displayPage == 0 && <DescriptionPage /> }
                { displayPage == 1 && <QuestionsAnswers /> }
                { displayPage == 2 && <AdvancedOptionsPage /> }
            </Page>
        </PulseContext.Provider>
    ) : (
        <RouteNotFound/>
    ))
};

export default Editor;