import axiosConfig from '../../axiosConfig';
import React, { useEffect, useState } from 'react';

import { DropdownOption } from '../app/common/Dropdown';
import { SidebarHeader, PageHeader } from './Headers';
import { showNotification } from '../../App';
import Preloader from './Preloader';

import GoBackImg from '../../img/go_back.svg';


export const Page = ({pageId, children}: {pageId: string, children: React.ReactNode}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showSidebar, setShowSidebar] = useState(true);

    const [projects, setProjects] = useState<DropdownOption[]>([]);

    useEffect(() => {
        getProjects();
    }, [])

    async function getProjects() {
        interface ResponseData {
            projects: DropdownOption[]
        }
        try {
            const response = await axiosConfig.get<ResponseData>('api/projects');
            
            if (response.data.projects) {
                if (!localStorage.getItem('project')) {
                    let defaultProject = response.data.projects[0];
                    localStorage.setItem('projectName', defaultProject.label);
                    localStorage.setItem('project', defaultProject.value.toString());
                }

                setProjects(response.data.projects);
            }
            setIsLoading(false);
        } catch {
            showNotification('Server error', true);
        }
    }

    return (
        <div id={pageId} className={"page " + (!showSidebar && "extended")}>
            {isLoading ? (
                <Preloader className={"page-preloader"}/>
            ) : (
                <div className="page-content col-9-5 col-m-9-5 col-s-12">
                    <PageHeader projects={projects}/>

                    {children}
                </div>
            )}

            {!isLoading  && (
                <button id="show-sidebar-btn">
                    <img
                        src={GoBackImg}
                        onClick={() => setShowSidebar(prevState => (!prevState))} >
                    </img>
                </button>
            )}
        </div>
    )
}

export const Sidebar = ({children}: {children: React.ReactNode}) => {
    return (
        <div className="sidebar col-2-5 col-m-2-5 col-s-12">
            <SidebarHeader />

            <div className="sidebar-content col-12 col-m-12 col-s-12">
                {children}
            </div>
        </div>
    )
}

export const MainContent = ({children}: {children: React.ReactNode}) => {
    return (
        <div className="main-content col-12 col-m-12 col-s-12">
            {children}
        </div>
    )
}

export const MainContentHeader = ({children}: {children: React.ReactNode}) => {
    return (
        <div className="main-content-header col-12 col-m-12 col-s-12 aligned-center">
            {children}
        </div>
    )
}
