import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import CopyLink from '../buttons/CopyLink';
import DuplicatePulse from '../buttons/DuplicatePulse';
import { Sidebar } from '../../base/PageCommon';
import Preloader from '../../base/Preloader';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';
import UpdateStatus from '../buttons/UpdateStatus';

import DescriptionImg from '../../../img/description.svg';
import DisplayImg from '../../../img/display.svg';
import QuestionsImg from '../../../img/questions.svg';
import SettingsImg from '../../../img/settings.svg';


interface EditorSidebarProps {
    displayPage: number,
    onDisplayPageChange: (newDisplayPage: number) => void
}
const EditorSidebar: FunctionComponent<EditorSidebarProps> = ({displayPage, onDisplayPageChange}) => {
    const pulseHash = useContext(PulseContext);
    const [anonymousLink, setAnonymousLink] = useState('');
    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        getPulse()
    }, [])

    async function getPulse() {
        try {
            const response = await axiosConfig.get(`api/pulses/${pulseHash}`);

            setAnonymousLink(response.data.anonymousLink);
            setStatus(response.data.status);
            setIsLoading(false);
        } catch {
            showNotification('Server error', true);
        }
    }

    return (
        <Sidebar>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <div className="pulse-actions col-12 col-m-12 col-s-12">
                        <ul className="aligned-center">
                            <li className="col-auto col-m-auto col-s-auto">
                                <CopyLink link={anonymousLink} />
                            </li>
                            <li className="col-auto col-m-auto col-s-auto">
                                <UpdateStatus initStatus={status} />
                            </li>
                            <li className="col-auto col-m-auto col-s-auto">
                                <DuplicatePulse />
                            </li>
                        </ul>
                    </div>

                    <div className="display-page-filter radio-btn-filter col-12 col-m-12 col-s-12">
                        <div className="header col-12 col-m-12 col-s-12 aligned-left">
                            <img src={DisplayImg}></img>
                            <span>Display</span>
                        </div>
                        <div className="radio-btn-wrapper col-12 col-m-12 col-s-12 aligned-left">
                            <input type="radio" value="0" id="description" checked={displayPage == 0} onChange={() => onDisplayPageChange(0)}/>
                            <label htmlFor="description" className="aligned-center">
                                <img src={DescriptionImg}></img>
                                Description
                            </label>
                        </div>
                        <div className="radio-btn-wrapper col-12 col-m-12 col-s-12 aligned-left">
                            <input type="radio" value="1" id="questions-answers" checked={displayPage == 1} onChange={() => onDisplayPageChange(1)}/>
                            <label htmlFor="questions-answers" className="aligned-center">
                                <img src={QuestionsImg}></img>
                                Questions and answers
                            </label>
                        </div>
                        <div className="radio-btn-wrapper col-12 col-m-12 col-s-12 aligned-left">
                            <input type="radio" value="2" id="advanced-options" checked={displayPage == 2} onChange={() => onDisplayPageChange(2)}/>
                            <label htmlFor="advanced-options" className="aligned-center">
                                <img src={SettingsImg}></img>
                                Advanced options
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </Sidebar>
    )
}

export default EditorSidebar;