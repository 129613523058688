import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useContext, useState } from 'react';

import MessageDialog from '../common/MessageDialog';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import DuplicateImg from '../../../img/duplicate.svg';


const DuplicatePulse:FunctionComponent = () => {
    const pulseHash = useContext(PulseContext);
    const [duplicateHash, setDuplicateHash] = useState('');

    async function duplicatePulse() {
        try {
            const response = await axiosConfig.post(`api/pulses/${pulseHash}/duplicate`);

            if (response.data.hash)
                setDuplicateHash(response.data.hash);
        } catch {
            showNotification('Server error', true);
        }
    }

    function onClick(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        duplicatePulse();
    }

    function onCloseMsgDialog() {
        setDuplicateHash('');
    }

    function onGoDuplicate() {
        window.location.href = `/pulses/${duplicateHash}/editor`;
    }

    return (
        <div>
            <a href="" className="round-btn">
                <img src={DuplicateImg} onClick={onClick}/>
            </a>

            {duplicateHash && (
                <MessageDialog onClose={onCloseMsgDialog}>
                    <div>
                        {'You have just created a duplicate of pulse.\n'}
                        {'Would you like to go to the duplicate?'}
                    </div>
                    <button className="aligned-center" onClick={onGoDuplicate}>GO</button>
                </MessageDialog>
            )}
        </div>
    )
}

export default DuplicatePulse;
