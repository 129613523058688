import { EdgeData, NodeData, PortData } from 'reaflow';

import { OptionI, QuestionI } from './Utils';


export const TERMINATOR_ID = 'terminator';
export const NODE_CLASS_NAME = 'custom-node';
export const EDGE_CLASS_NAME = 'custom-edge';
export const PORT_CLASS_NAME = 'custom-port';
export const HAS_CYCLE_ERROR = 'This pulse will have cycle.';
export const DEL_START_NODE_ERROR = "You can't delete start question.";
export const HAS_ONE_EDGE_ERROR = 'Questions must contain at least one option.';

export function convertQuestions(questions: QuestionI[]): [NodeData[], EdgeData[]] {
    let nodes: NodeData[] = [],
        edges: EdgeData[] = [];

    questions.forEach(question => {
        const node = getNode(question)
        nodes.push(node);

        question.options.forEach(option => {
            edges.push(getEdge(option, node.id, option.target));
        })
    });

    nodes.push({
        id: TERMINATOR_ID,
        text: 'Terminator',
        className: NODE_CLASS_NAME
    });

    return [nodes, edges]
}

export function getPort(nodeId: string): PortData {
    return {
        id: `port-${nodeId}`,
        className: PORT_CLASS_NAME,
        height: 12,
        width: 12,
        side: 'SOUTH'
    }
}

export function getNode(question: QuestionI): NodeData {
    const nodeId = question.id.toString();
    return {
        id: nodeId,
        text: question.text,
        className: NODE_CLASS_NAME,
        height: 60,
        width: 300,
        ports: [getPort(nodeId)]
    }
}

export function getEdge(option: OptionI, fromId: string, toId: string): EdgeData {
    return {
        id: option.id.toString(),
        text: option.text,
        from: fromId,
        to: toId,
        className: EDGE_CLASS_NAME,
        fromPort: `port-${fromId}`
    }
}