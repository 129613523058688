import React, { FunctionComponent, useEffect, useState, useRef } from 'react';

import RadioGroup from './../common/RadioGroup';
import { defaultOnOffOptions } from '../../base/Constants';


export interface LandingPageOptions {
    startPage: number,
    skipButton: number,
    entryText: string
}
interface LandingPageSettingsProps {
    settingsOptions: LandingPageOptions,
    onChange: (options: LandingPageOptions) => void
}

const LandingPageSettings: FunctionComponent<LandingPageSettingsProps> = ({settingsOptions, onChange}) => {
    const isInitialMount = useRef(true);
    const [options, setOptions] = useState(settingsOptions);

    useEffect(() => {
        if (isInitialMount.current)
            isInitialMount.current = false;
        else
            onChange(options);
    }, [options])

    return (
        <div className="col-12">
            <RadioGroup 
                className="radio-group col-2 col-m-3 col-s-6 border"
                title="Start page"
                name="startPage"
                value={settingsOptions.startPage} 
                options={defaultOnOffOptions}
                onChange={(value) => setOptions(prevState => ({...prevState, startPage: value}))}
            />
            <RadioGroup 
                className="radio-group col-3 col-m-3 col-s-6"
                title="Skip button" 
                name="skipButton"
                value={settingsOptions.skipButton} 
                options={defaultOnOffOptions}
                onChange={(value) => setOptions(prevState => ({...prevState, skipButton: value}))}
            />
            <textarea 
                id="landing-page-text"
                className="col-12"
                placeholder="Landing page text..."
                value={settingsOptions.entryText}
                onChange={(e) => setOptions(prevState => ({...prevState, entryText: e.target.value}))}> 
            </textarea>
        </div>
    )
}

export default LandingPageSettings;