import { 
    detectCircular, getParentsForNodeId, getEdgesByNode,
    Canvas, CanvasPosition, 
    CanvasRef, MarkerArrow, Edge, EdgeData,
    Node, NodeData, Port } from 'reaflow';
import React, { FunctionComponent, useState, useRef, useContext } from "react";

import { 
    addOption, addQuestion, 
    deleteOption, deleteQuestion, 
    modifyOption, modifyQuestion, 
    QuestionI } from './Utils';
import { 
    TERMINATOR_ID, DEL_START_NODE_ERROR,
    HAS_CYCLE_ERROR, HAS_ONE_EDGE_ERROR, 
    getEdge, getNode, convertQuestions } from './GraphUtils';
import { Selection, EditorContainer} from './GraphEditor'; 
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import MinusImg from '../../../img/minus.svg';
import PlusImg from '../../../img/plus.svg';


interface GraphProps {
    questions: QuestionI[],
    refresh: () => void
}

const Graph: FunctionComponent<GraphProps> = ({ questions, refresh }) => {
    const pulseHash = useContext(PulseContext);

    const ref = useRef<CanvasRef | null>(null);
    const [zoom, setZoom] = useState(0.7);

    const [_nodes, _edges] = convertQuestions(questions);

    const [nodes, setNodes] = useState<NodeData[]>(_nodes);
    const [edges, setEdges] = useState<EdgeData[]>(_edges);
    const [selection, setSelection] = useState<Selection | null>(null);


    function onNodeClick(_event: React.MouseEvent<SVGGElement, MouseEvent>, node: NodeData) {
        if (node.id != TERMINATOR_ID) {
            setSelection({type: 'node', source: node});
        }
    }

    function onEdgeClick(_event: React.MouseEvent<SVGGElement, MouseEvent>, edge: EdgeData) {
        setSelection({type: 'edge', source: edge});
    }

    function addElem(selection: Selection) {
        if (selection.type == 'node') {
            addNode(selection.source.id)
        }
    }

    async function addEdge(from: NodeData, to: NodeData) {
        const target = to.id === TERMINATOR_ID ? -1 : +to.id;
        const newOption = await addOption(pulseHash, '', +from.id, target);
        if (newOption) {
            const edge = getEdge(newOption, from.id, to.id);
            setEdges(prevState => [
                ...prevState,
                edge
            ]);
            setSelection({type: 'edge', source: edge});
        }
    }

    async function addNode(sourceNodeId: string) {
        const newQuestion = await addQuestion(pulseHash, '', +sourceNodeId, 'false');
        if (newQuestion) {
            const node = getNode(newQuestion);
            setNodes(prevState => [
                ...prevState,
                node
            ]);
            setEdges(prevState => [
                ...prevState,
                getEdge(newQuestion.options[0], sourceNodeId, node.id)
            ]);
            setSelection({type: 'node', source: node});
        }
    }
    
    function deleteElem(selection: Selection) {
        if (selection.type == 'node') {
            deleteNode(selection.source)
        } else {
            deleteEdge(selection.source)
        }
    }

    async function deleteEdge(selectedEdge: EdgeData) {
        const node = nodes.filter(node => node.id == selectedEdge.to);
        if (node && getEdgesByNode(edges, node[0]).to.length <= 1) {
            showNotification(HAS_ONE_EDGE_ERROR, true);
            return;
        }

        if (await deleteOption(pulseHash, +selectedEdge.id)) {
            setEdges(edges.filter(edge => edge.id != selectedEdge.id));
            setSelection(null);
        }
    }

    async function deleteNode(selectedNode: NodeData) {
        if (nodes.length <= 2 || getParentsForNodeId(nodes, edges,selectedNode.id).length == 0) {
            showNotification(DEL_START_NODE_ERROR, true);
            return;
        }

        if(await deleteQuestion(pulseHash, +selectedNode.id)) {
           refresh();
        }
    }

    function modifyElem(selection: Selection, newSelectionText: string) {
        if (selection.type == 'node') {
            modifyNode(selection.source.id, newSelectionText)
        } else {
            modifyEdge(selection.source.id, newSelectionText)
        }
    }

    async function modifyEdge(edgeId: string, newEdgeText: string) {
        if (await modifyOption(pulseHash, +edgeId, newEdgeText)) {
            setEdges(prevState => {
                const newState = prevState.map(edge => {
                    if (edge.id === edgeId) return {...edge, text: newEdgeText};
                    return edge;
                });
                return newState;
            });
        }
    }

    async function modifyNode(nodeId: string, newNodeText: string) {
        if (await modifyQuestion(pulseHash, +nodeId, newNodeText)) {
            setNodes(prevState => {
                const newState = prevState.map(node => {
                    if (node.id === nodeId) return {...node, text: newNodeText};
                    return node;
                });
                return newState;
            });
        }
    }

    return (
        <div className="graph-container">
            <Canvas 
                defaultPosition={CanvasPosition.TOP}
                height={750}
                zoom={zoom}
                ref={ref}
                selections={selection ? [selection.source.id] : []}
                nodes={nodes}
                edges={edges} 
                node={<Node onClick={onNodeClick} port={<Port rx={10} ry={10} />} rx={10} ry={10}/>}
                edge={<Edge onClick={onEdgeClick} />}
                arrow={<MarkerArrow className={'custom-arrow'}/>}
                onNodeLink={(_event, from: NodeData, to: NodeData) => {
                    addEdge(from, to);
                }}
                onNodeLinkCheck={(_event, from: NodeData, to: NodeData) => {
                    if (from.id === to.id) {
                        return false;
                    }

                    if (detectCircular(nodes, edges, from, to)) {
                        showNotification(HAS_CYCLE_ERROR);
                        return false;
                    }

                    return true;
                }}
                onZoomChange={(z) => {
                    setZoom(z);
                }}
            />

            <div className="aligned-center">
                <button className="zoom-in round-btn">
                    <img src={PlusImg} onClick={() => ref.current?.zoomIn?.()}></img>
                </button>
                <button className="zoom-out round-btn">
                    <img src={MinusImg} onClick={() => ref.current?.zoomOut?.()}></img>
                </button>
                <div className="fit-graph-wrapper aligned-center">
                    <button className="fit-graph" onClick={() => ref.current?.fitCanvas?.()}>
                        Fit
                    </button>
                </div>
            </div>

            {selection && (
                <EditorContainer 
                    selection={selection}
                    onAdd={() => addElem(selection)}
                    onDelete={() => deleteElem(selection)}
                    onClose={() => setSelection(null)}
                    onModify={(newSelectionText, selection) => modifyElem(selection, newSelectionText)}
                />
            )}
        </div>
    );
}

export default Graph;