import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useContext, useState } from 'react';

import MessageDialog from '../common/MessageDialog';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import DeleteImg from '../../../img/delete.svg';


interface DeletePulseProps {
    pulseName: string,
    onDelete: () => void
}

const DeletePulse:FunctionComponent<DeletePulseProps> = ({ pulseName, onDelete }) => {
    const pulseHash = useContext(PulseContext);
    const [confirmDlg, setConfirmDgl] = useState(false);

    async function deletePulse() {
        try {
            await axiosConfig.delete(`api/pulses/${pulseHash}`);

            onDelete();
            showNotification('Deleted');
        } catch {
            showNotification('Server error', true);
        }
    }

    function onClickDelete(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        setConfirmDgl(true);
    }

    function onCloseMsgDialog() {
        setConfirmDgl(false);
    }

    function onDeleteConfirm() {
        deletePulse()
    }

    return (
        <div>
            <a href="" className="round-btn">
                <img src={DeleteImg} onClick={onClickDelete}/>
            </a>

            {confirmDlg && (
                <MessageDialog onClose={onCloseMsgDialog}>
                    <div>
                        {'You are about to delete '}<span>{pulseName}</span>{' pulse.'}
                        <br></br>{'This operation can not be reverted!'}
                    </div>
                    <button className="aligned-center" onClick={onDeleteConfirm}>OK</button>
                </MessageDialog>
            )}
        </div>
    )
}

export default DeletePulse;
