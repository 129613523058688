import React, { FunctionComponent, useEffect, useState, useRef } from 'react';

import RadioGroup from './../common/RadioGroup';
import { defaultOnOffOptions, defaultSlideSteps }  from '../../base/Constants';


export interface PeerPredOptions {
    peerPrediction: number,
    sliderStep: number,
    questionText: string
}
interface PeerPredSettingsProps {
    settingsOptions: PeerPredOptions,
    onChange: (options: PeerPredOptions) => void
}

const PeerPredSettings: FunctionComponent<PeerPredSettingsProps> = ({settingsOptions, onChange}) => {
    const isInitialMount = useRef(true);
    const [options, setOptions] = useState(settingsOptions);

    useEffect(() => {
        if (isInitialMount.current)
            isInitialMount.current = false;
        else
            onChange(options);
    }, [options]);

    return (
        <div className="col-12">
            <RadioGroup 
                className="radio-group col-2 border"
                title="Peer prediction" 
                name="peerPrediction"
                value={options.peerPrediction} 
                options={defaultOnOffOptions}
                onChange={(value) => setOptions(prevState => ({...prevState, peerPrediction: value}))} 
            />
            <RadioGroup 
                className="radio-group col-3"
                title="Slider increment"
                name="sliderStep" 
                value={options.sliderStep} 
                options={defaultSlideSteps}
                onChange={(value) => setOptions(prevState => ({...prevState, sliderStep: value}))} 
            />
            <textarea
                id="question-text"
                className="col-12"
                placeholder="Question..."
                value={options.questionText}
                onChange={(e) => setOptions(prevState => ({...prevState, questionText: e.target.value}))} >
            </textarea>
        </div>
    )
}


export default PeerPredSettings;