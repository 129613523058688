import React, { FunctionComponent } from "react";

import Dropdown, { DropdownOption } from './Dropdown';
import RowPulse, { RowPulseProps } from '../pulses/PulseRow';
import {RowQuestion, QuestionProps } from '../results/Question';

import ArrowLeft from '../../../img/arrow_left.svg';
import ArrowRight from '../../../img/arrow_right.svg';

const options: DropdownOption[] = [
    { label: '2', value: 2 },
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 }
];

interface TableProps {
    title: string,
    data: (RowPulseProps | QuestionProps)[],
    page: number,
    perPage: number,
    rowsCnt: number,
    onChange: (page: number, perPage: number) => void,
}

const Table: FunctionComponent<TableProps> = (props) => {
    const firstIndexPulse = (props.page * props.perPage) + 1;
    let lastIndexPulse = firstIndexPulse + props.perPage - 1;
    if (lastIndexPulse > props.rowsCnt) lastIndexPulse = props.rowsCnt;

    const onLastPage = lastIndexPulse >= props.rowsCnt;
    const onFirstPage = props.page === 0;

    const handlePerPageChange = (value: string) => {
        props.onChange(0, +value)
    };

    const goToPrevPage = () => {
        props.onChange(props.page - 1, props.perPage)
    };

    const goToNextPage = () => {
        props.onChange(props.page + 1, props.perPage)
    };

    return (
        <div className="table-wrapper">
            <table className="col-12 col-m-12 col-s-12">
                <tbody>
                    {props.data.map((item, index) => {
                        if ('hash' in item) {
                            return <RowPulse key={item.hash} {...item} />;
                        } else {
                            const question_indx = index + 1 + (props.page * props.perPage);
                            return <RowQuestion key={item.id} {...{index:question_indx, ...item}}/>;
                        }
                    })}
                </tbody>
            </table>
            <div className="pagination col-12 col-m-12 col-s-12 aligned-right">
                <Dropdown
                    key={Math.random()}
                    label="Rows per page:"
                    options={options}
                    value={props.perPage}
                    onChange={handlePerPageChange}
                />

                <div>
                    <label>{props.title}:&nbsp;&nbsp;<b>{firstIndexPulse} - {lastIndexPulse}</b> (of {props.rowsCnt})</label>
                    <button className="arrow-left" disabled={onFirstPage} onClick={goToPrevPage}>
                        <img src={ArrowLeft}></img>
                    </button>
                    <button className="arrow-right" disabled={onLastPage} onClick={goToNextPage}>
                        <img src={ArrowRight}></img>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Table;