import React, { FunctionComponent } from 'react';

interface RadioGroupProps {
    className?: string,
    title: string,
    name: string,
    value: number,
    options: { label: string, value: number }[],
    onChange: (value: number) => void
}

const RadioGroup:FunctionComponent<RadioGroupProps> = (props) => {
    const valuesLen = props.options.length;

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.onChange(+event.target.value);
    }

    return (
        <div className={props.className ? props.className : ""}>
            <div className="header col-12 col-m-12 col-s-12 aligned-left">{props.title}</div>
            {props.options.map((option) => {
                return (
                    <div className={`radio-btn-wrapper col-${24 / valuesLen } col-m-${24 / valuesLen} col-s-${24 / valuesLen } aligned-left`}>
                        <input type="radio" value={option.value} id={option.label} name={props.name} checked={props.value == option.value} onChange={onChange}/>
                        <label htmlFor={option.label}>{option.label}</label>
                    </div>
                )
            })}
        </div>
    );
}

export default RadioGroup;