import React from 'react';
import { CookiesProvider } from 'react-cookie';

import Router from './components/base/Router';
import NotificationsManager, { NotifyFn } from './components/base/NotificationsManager';


let notify: NotifyFn;

function App() {
  return (
    <CookiesProvider>
        <Router/>

        <NotificationsManager 
          setNotify={(notifyFn: NotifyFn) => {
            notify = notifyFn;
          }} />
    </CookiesProvider>
  );
}

export function showNotification(message: string, error: boolean = false) {
  return notify({
    message,
    error: error
  });
}

export default App;
