import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useContext, useState } from 'react';

import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import RunImg from '../../../img/run.svg';
import PauseImg from '../../../img/pause.svg';


interface UpdateStatusProps {
    initStatus: number
}

const UpdateStatus:FunctionComponent<UpdateStatusProps> = ({initStatus}) => {
    const pulseHash = useContext(PulseContext);
    const [status, setStatus] = useState(initStatus);

    async function updatePulseStatus() {   
        try {
            const newStatus = status == 0 ? 1 : 0;

            const response = await axiosConfig.patch(`api/pulses/${pulseHash}`, {
                status: newStatus
            });

            showNotification(response.data.status);
            setStatus(newStatus);
        } catch {
            showNotification('Server error', true);
        }
    }

    function onClick(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        updatePulseStatus();
    }

    return (
        <a href="" className="round-btn">
            { status == 0
                ? <img src={RunImg} onClick={onClick}/>
                : <img src={PauseImg} onClick={onClick}/> 
            }
        </a>
    )
}

export default UpdateStatus;
