import debounce from 'lodash.debounce';
import React, { FunctionComponent, useCallback, useEffect, useState, useRef, useContext } from "react";

import Options from "./Options";
import { QuestionI, addQuestion, deleteQuestion, modifyQuestion} from './Utils';
import { DEL_START_NODE_ERROR } from './GraphUtils';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import DeleteImg from '../../../img/delete.svg';
import QuestionImg from '../../../img/question1.svg';


export interface QuestionProps {
    index: number,
    question?: QuestionI,
    onDelete?: () => void,
    onAdd?: (questionText: string) => void
    onModify?: (questionText: string, questionId: number) => void
}
export interface QuestionsProps {
    _questions: QuestionI[]
}

export const Question: FunctionComponent<QuestionProps> = ({index, question, onAdd, onDelete, onModify}) => {
    const isInitialMount = useRef(true);

    const [active, setActive] = useState(false);
    const [questionText, setQuestionText] = useState(question ? question.text : '');

    const debouncedSave = useCallback(
        debounce(async (newQuestionText: string, questionId: number) => {
            if (onModify)
                await onModify(newQuestionText, questionId);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (question) {
            if (isInitialMount.current)
                isInitialMount.current = false;
            else
                debouncedSave(questionText, question.id);
        }
    }, [questionText, debouncedSave]);

    function onKeyUp(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (event.key === 'Enter') {
            if (question == undefined && onAdd) {
                showNotification('Saving');
                onAdd(questionText);
                
                setQuestionText('');
            }
        }
    }

    function onClickDelete(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        if (question && onDelete) 
            onDelete();
    }

    return (
        <div className="question col-12 col-m-12 col-s-12 aligned-right" onClick={() => setActive(true)}> 
            <div className="col-12 col-m-12 col-s-12 aligned-center">
                <div className="question-index col-2 col-m-2 col-s-12 aligned-left">
                    <img src={QuestionImg}></img>
                    {question  ? 'Question ' + index : 'New question'}
                </div>

                <div className="col-9 col-m-9 col-s-10">
                    <textarea 
                        id="question-text"
                        className="col-12 col-m-12 col-s-12"
                        placeholder="Ask you question..."
                        value={questionText}
                        onKeyUp={onKeyUp}
                        onChange={(e) => setQuestionText(e.target.value)}>
                    </textarea>
                </div>

                <div className="question-actions col-1 col-m-1 col-s-2">
                    {question && (
                        <div className="col-12 col-m-12 col-s-12 aligned-center">
                            <a href="" className="round-btn">
                                <img src={DeleteImg} onClick={onClickDelete} />
                            </a>
                        </div>
                    )}  
                </div> 
            </div>

            <span className="separator col-10 col-m-10 col-s-11"></span>
            
            {question && (
                <Options 
                    questionId={question.id}
                    showTemplate={active}
                    _options={question.options}
                />
            )}
        </div>
    )
}

const Questions: FunctionComponent<QuestionsProps> = ({ _questions }) => {
    const pulseHash = useContext(PulseContext);
    const [questions, setQuestions] = useState<QuestionI[]>(_questions);

    async function onDeleteQuestion(questionId: number) {
        if (questions.length <= 1 || questionId == questions[0].id) {
            showNotification(DEL_START_NODE_ERROR, true);
            return;
        }

        if (await deleteQuestion(pulseHash, questionId))
            setQuestions(questions.filter(question => question.id != questionId));
    }

    async function onAddQuestion(questionText: string) {
        const newQuestion = await addQuestion(pulseHash, questionText, questions[questions.length - 1].id, 'true');
        if (newQuestion)
            setQuestions((prevState) => [...prevState, newQuestion]);
    }

    async function onModifyQuestion(questionText: string, questionId: number) {
        modifyQuestion(pulseHash, questionId, questionText);
    }

    return (
        <div className="questions">
            {questions.map((question, index) => {
                return  <Question 
                    key={'question-' + question.id}
                    index={index + 1}
                    question={question}
                    onDelete={() => onDeleteQuestion(question.id)}
                    onModify={onModifyQuestion} 
                />
            })}
            <Question 
                key='question-template'
                index={0}
                onAdd={onAddQuestion} 
            />
        </div>
    )
}

export default Questions;