import React, { useState } from "react";

export interface DropdownOption {
    value: string | number,
    label: string,

}
interface DropdownProps {
    label: string,
    value: string | number | null,
    defaultVal?: string | number,
    imgSrc?: string,
    options: Array<DropdownOption>,
    onChange: (value: string) => void
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({ label, value, defaultVal, imgSrc, options, onChange }) => {
    const [selected, setSelected] = useState(value ? value : defaultVal ? defaultVal : null)
    const [isOpen, setIsOpen] = useState(false);
  
    function onClick(event: React.MouseEvent<HTMLElement>) {
      if (event.currentTarget.className == 'selected' && !isOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);

        if (event.currentTarget.className != 'selected') {
          setSelected(event.currentTarget.id);
          onChange(event.currentTarget.id);
        }
      }
    }

    return (
      <div className="dropdown col-auto col-m-auto col-s-auto">
        {label.length > 0 && <label>{label}</label>}

        <div className={"select aligned-between" + (isOpen ? ' open' : '')}
              onMouseMove={() => setIsOpen(true)} 
              onMouseLeave={() => setIsOpen(false)} >
          {options.map((option) => (
            <button 
              id={option.value.toLocaleString()} 
              className={option.value == selected ? 'selected' : '' }
              onClick={onClick}>
                {imgSrc && !isOpen && option.value == selected && <img src={imgSrc}></img>}
                {option.label}
              </button>
          ))}
        </div>
      </div>
    );
};

export default Dropdown;