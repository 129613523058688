import React from 'react';
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';

import Editor from '../app/editor/Editor';
import Pulses from '../app/pulses/PulsesList';
import LoginPage from './LoginPage';
import RouteNotFound from './RouteNotFound';
import Results from '../app/results/Results';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='' element={ <Pulses/> }/>
                <Route path='/'>
                    <Route path='login' element={ <LoginPage/> }/>
                    <Route path='pulses' element={ <Pulses/> }/>
                    <Route path='pulses/:hash/editor' element={ <Editor/> }/>
                    <Route path='pulses/:hash/results' element={ <Results/> }/>
                    <Route path='*' element={ <RouteNotFound/> }/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default Router;