import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CopyLink from '../buttons/CopyLink';
import DeletePulse from '../buttons/DeletePulse';
import Respondents from '../results/Respondents';
import { PulseContext } from '../../base/Context';

import ResultImg from '../../../img/result.svg';
import EditImg from '../../../img/edit.svg';

export interface Pulse {
    hash: string,
    name: string,
    status: string,
    creationDate: string,
    anonymousLink: string
}
export interface RowPulseProps extends Pulse {
    onDelete: () => void,
}

const RowPulse: FunctionComponent<RowPulseProps> = (props) => {
    const navigate = useNavigate();
    const isMobile = window.screen.width < 600 ? true : false;

    const [displayDetails, setDisplayDetails] = useState(false);

    function handleEditButton() {
        navigate(`/pulses/${props.hash}/editor`);
    };

    function handleResultsButton() {
        navigate(`/pulses/${props.hash}/results`);
    };

    function handleDetailsButton(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        const display = displayDetails ? false : true;
        setDisplayDetails(display);
    };

    return (
        <PulseContext.Provider value={props.hash}>
            <tr id={props.hash} className={"pulse-row col-12 col-m-12 col-s-12 aligned-right" + (displayDetails ? ' extended-row' : '')}>
                <td className="name aligned-between">
                    <CopyLink link={props.anonymousLink} />

                    <a href="">
                        <span onClick={handleDetailsButton}>{props.name}</span>
                    </a>
                </td>

                { isMobile && (
                    <td className="date-status aligned-left">
                        <div className="status col-s-12">
                            Status:&nbsp;&nbsp;
                            <span>{props.status}</span>
                        </div>
                        <div className="date col-s-12">
                           {props.creationDate}
                        </div>
                    </td>           
                )}

                { !isMobile && (
                    <td className="status aligned-center">
                        Status:&nbsp;&nbsp;
                        <span>{props.status}</span>
                    </td>
                )}
                { !isMobile && (
                     <td className="date aligned-center">{props.creationDate}</td>
                )}
   
                <td className="actions aligned-between">
                    <a href="" className="round-btn col-auto col-m-auto col-s-12">
                        <img src={ResultImg} onClick={handleResultsButton}/>
                    </a>
                    <a href="" className="round-btn col-auto col-m-auto col-s-12">
                        <img src={EditImg} onClick={handleEditButton}/>
                    </a>

                    <DeletePulse pulseName={props.name} onDelete={props.onDelete}/>
                </td>
                {displayDetails && (
                    <td className="respondents-stats col-12 col-m-12 col-s-12">
                        <Respondents />
                    </td>
                )}
            </tr>
        </PulseContext.Provider>
    );
};

export default RowPulse;