import axiosConfig from '../../../axiosConfig';

import { showNotification } from '../../../App';

export interface OptionI {
    id: number,
    text: string,
    target: string
}
export interface QuestionI {
    id: number,
    text: string,
    options: OptionI[]
}

export async function addOption(pulseHash: string, optionText: string, sourceId: number, targetId?: number): Promise<OptionI | null> {
    interface ResponseData {
        option: OptionI
    }
    try {   
        const response = await axiosConfig.post<ResponseData>(`api/pulses/${pulseHash}/option`, {
            optionText: optionText.trim(),
            sourceId: sourceId,
            targetId: targetId
        });    
        
        showNotification('Added');
        return response.data.option;
    } catch {
        showNotification('Server error', true);
        return null;
    }    
}

export async function addQuestion(pulseHash: string, questionText: string, sourceQuestion: number, lastQuestion: string): Promise<QuestionI | null> {
    interface ResponseData {
        question: QuestionI
    }
    try {
        const response = await axiosConfig.post<ResponseData>(`api/pulses/${pulseHash}/question`, {
            questionText: questionText.trim(),
            sourceQuestionId: sourceQuestion,
            lastQuestion: lastQuestion
        });

        showNotification('Added');
        return response.data.question;
    } catch {
        showNotification('Server error', true);
        return null;
    }
}

export async function deleteOption(pulseHash: string, optionId: number): Promise<boolean> {
    try {
        const response = await axiosConfig.delete(`api/pulses/${pulseHash}/option`, {
            data: {
                id: optionId
            }
        });

        if (response.data.error) {
            showNotification(response.data.error, true);
            return false;
        } else {
            showNotification('Deleted');
            return true;
        }
    }  catch {
        showNotification('Server error', true);
        return false;
    }
}

export async function deleteQuestion(pulseHash: string, questionId: number): Promise<boolean> {
    try {
        const response = await axiosConfig.delete(`api/pulses/${pulseHash}/question`, {
            data: {
                id: questionId
            }
        });

        if (response.data.error){
            showNotification(response.data.error, true);
            return false;
        } else {
            showNotification('Deleted');
            return true;
        }
    } catch {
        showNotification('Server error', true);
        return false;
    }
}

export async function modifyOption(pulseHash: string, optionId: number, optionText: string): Promise<boolean> {
    try {
        await axiosConfig.put(`api/pulses/${pulseHash}/option`, {
            id: optionId,
            text: optionText.trim()
        });

        showNotification('Saved');
        return true;
    } catch {
        showNotification('Server error', true);
        return false;
    }
}

export async function modifyQuestion(pulseHash: string, questionId: number, questionText: string): Promise<boolean> {
    try {
        await axiosConfig.put(`api/pulses/${pulseHash}/question`, {
            id: questionId,
            text: questionText.trim()
        });

        showNotification('Saved');
        return true;
    } catch {
        showNotification('Server error', true);
        return false;
    }
}