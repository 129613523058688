import React, { FunctionComponent, useEffect } from "react";

export interface NotificationI {
    message: string,
    error: boolean
}
interface NotificationProps extends NotificationI {
    onDelete: () => void;
}

const Notification:FunctionComponent<NotificationProps> = (props) => {
    useEffect(() => {
        setTimeout(() => props.onDelete(), 3500);
    }, []);

    return (
        <div className={"notification aligned-center " + (props.error ? "error" : "")}>{props.message}</div>
    )
}

export default Notification;