import axiosConfig from '../../../axiosConfig';
import debounce from 'lodash.debounce';
import React, { FunctionComponent, useCallback, useState, useEffect, useRef, useContext } from 'react';

import FinalFormSettings, { FinalFormOptions, Location } from './FinalFormSettings';
import LandingPageSettings, { LandingPageOptions } from './LandingPageSettings';
import LanguageSettings, { Language, LanguageOptions } from './LanguageSettings';
import { MainContent, MainContentHeader } from '../../base/PageCommon';
import PeerPredSettings, { PeerPredOptions } from './PeerPredSettings';
import Preloader  from '../../base/Preloader';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import ConfirmImg from '../../../img/confirm.svg'
import LocationImg from '../../../img/location.svg';
import TextImg from '../../../img/text.svg';
import QuestionImg from '../../../img/question.svg';


export interface AdvOptions {
    languageOptions: LanguageOptions,
    landingPageOptins: LandingPageOptions,
    peerPredOptions: PeerPredOptions,
    finalFormOptions: FinalFormOptions
}
interface AdvOptionProps {
    advOptionsDefault: AdvOptions, 
    locations: Location[],
    languages: Language[]
}
interface SettingsBlockProps {
    id: string, 
    imgSrc: string, 
    children: React.ReactNode
}

const AdvancedOptions:FunctionComponent<AdvOptionProps> = ({advOptionsDefault, locations, languages}) => {
    const pulseHash = useContext(PulseContext);
    const isInitialMount = useRef(true);
    const [advOptions, setAdvOptions] = useState<AdvOptions>(advOptionsDefault);

    const debouncedSave = useCallback(
        debounce(async (newAdvOptions: AdvOptions) => {
          await saveData(newAdvOptions);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (isInitialMount.current)
            isInitialMount.current = false;
        else
            debouncedSave(advOptions);
    }, [advOptions, debouncedSave]);


    function saveData(newAdvOptions: AdvOptions) {  
        try {
            axiosConfig.put(`api/pulses/${pulseHash}/adv_options`, {
                ...newAdvOptions
            });

            showNotification('Saved');
            console.log('saved adv options'); //TODO remove after testing
        } catch {
            showNotification('Server error', true);
        }
    }

    function onAdvOptionsChange(optionsType: string, options: FinalFormOptions | PeerPredOptions | LandingPageOptions | LanguageOptions) {
        setAdvOptions(prevState => ({
            ...prevState,
            [optionsType]: options
        }));
    }

    return (
        <div id="pulse-advanced-options" className="container col-12 col-m-12 col-s-12">
            <div className="container-inner col-12 col-m-12 col-s-12">
                <SettingsBlock id="lang-settings" imgSrc={LocationImg}>
                    <LanguageSettings 
                        settingsOptions={advOptions.languageOptions}
                        languages={languages}
                        onChange={(options) => onAdvOptionsChange('languageOptions', options)}
                    />
                </SettingsBlock>

                <SettingsBlock id="land-page-setting" imgSrc={TextImg}>
                    <LandingPageSettings 
                        settingsOptions={advOptions.landingPageOptins}
                        onChange={(options) => onAdvOptionsChange('landingPageOptins', options)}
                    />
                </SettingsBlock>
                
                <SettingsBlock id="peer-pred-settings" imgSrc={QuestionImg}>
                    <PeerPredSettings 
                        settingsOptions={advOptions.peerPredOptions}
                        onChange={(options) => onAdvOptionsChange('peerPredOptions', options)}
                    />
                </SettingsBlock> 

                <SettingsBlock id="final-form" imgSrc={ConfirmImg}>
                    <FinalFormSettings 
                        settingsOptions={advOptions.finalFormOptions}
                        locations={locations}
                        onChange={(options) => onAdvOptionsChange('finalFormOptions', options)}
                    />
                </SettingsBlock>
            </div>
        </div>
    );
}

const AdvancedOptionsPage:FunctionComponent = () => {
    const pulseHash = useContext(PulseContext);
    const [isLoading, setIsLoading] = useState(true);

    const [advOptions, setAdvOptions] = useState<AdvOptions>();
    const [locations, setLocations] = useState<Location[]>();
    const [languages, setLanguages] = useState<Language[]>();

    useEffect(() => {
        getData();
    }, [])

    async function getData() {
        interface ResponseData {
            advOptions: AdvOptions,
            locations: Location[],
            languages: Language[]
        }
        try {
            const response = await axiosConfig.get<ResponseData>(`api/pulses/${pulseHash}/adv_options`);

            setAdvOptions(response.data.advOptions);
            setLanguages(response.data.languages);
            setLocations(response.data.locations);

            setIsLoading(false);
        } catch {
            showNotification('Server error', true);
        };
    }

    return (
        <MainContent>
            <MainContentHeader>
                Advanced options
            </MainContentHeader>
                    
            {(!isLoading && advOptions && languages && locations) ? (
                <AdvancedOptions 
                    advOptionsDefault={advOptions} 
                    languages={languages} 
                    locations={locations}
                />
            ) : (
                <Preloader />
            )}
        </MainContent>
    )
}

const SettingsBlock: FunctionComponent<SettingsBlockProps> = ({id, imgSrc, children}) => {
    return (
        <div id={id} className="settings-block col-12 col-m-12 col-s-12 aligned-left">
            <div className="img-wrapper col-1 col-m-1 col-s-12 aligned-center">
                <img src={imgSrc}></img>
            </div>
            <div className="col-11 col-m-11 col-s-12 aligned-left">
                {children}
            </div>
        </div>
    )
}

export default AdvancedOptionsPage;