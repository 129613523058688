export const defaultYesNoOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 }
]
export const defaultOnOffOptions = [
    { label: 'On', value: 1 },
    { label: 'Off', value: 0 }
]
export const defaultSlideSteps = [
    { label: '1%', value: 1 },
    { label: '3%', value: 3 },
    { label: '5%', value: 5 },
    { label: '10%', value: 10 }
]
