import React, { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { APP_NAME } from '../../AppConfig';
import Dropdown, { DropdownOption } from '../app/common/Dropdown';

import LogoutImg from '../../img/logout.svg';
import ProjectImg from '../../img/project.svg';


export const SidebarHeader = () => {
    return (
        <div className="sidebar-header col-12 col-m-12 col-s-12 aligned-center">
            <div className="title">
                <Link to="/pulses">{APP_NAME}</Link>
            </div>
        </div>
    )
}

export const PageHeader: FunctionComponent<{projects: DropdownOption[]}> = ({projects}) => {
    let navigate = useNavigate();

    function handleLogout(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        localStorage.clear();
        navigate('/login', { replace: true });
    }

    function onProjectChange(value: string) {
        localStorage.setItem('project', value);
        window.location.href = '/pulses';
    }

    return (
        <div className="page-header col-9-5 col-m-9-5 col-s-12">
            <div className="navbar col-12 col-m-12 col-s-12 aligned-center">
                <ul className="col-12 col-m-12 col-s-12 aligned-center">
                    <li className="project-navigate col-2-5 col-m-auto col-s-6 aligned-left">
                        {projects.length > 1 ? (
                            <Dropdown 
                                label=""
                                imgSrc={ProjectImg}
                                options={projects}
                                value={localStorage.getItem('project')}
                                onChange={onProjectChange}
                            />
                        ) : projects.length == 1 ? (
                            <div className="project-name col-12 col-m-12 col-s-12">
                                <img src={ProjectImg}></img>
                                <span>{localStorage.getItem('projectName')}</span>
                            </div>
                        ) : (
                            <span>No project</span>
                        )}

                    </li>
                    <li className="col-auto col-m-auto col-s-6 push-right"> 
                        <a href="" className="aligned-center" onClick={handleLogout}>
                            <img src={LogoutImg}></img>
                            <span>Logout</span>
                        </a>
                    </li> 
                </ul>
            </div>
        </div>
    );
}
