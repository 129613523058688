import html2canvas from 'html2canvas';
import React, { FunctionComponent } from 'react';

import PictureImg from '../../../img/picture.svg';

interface ExportPngProps {
    name: string,
    data: HTMLCollectionOf<HTMLElement>;
}

const ExportPng:FunctionComponent<ExportPngProps> = (props) =>  {
    async function getImage(element: HTMLElement) {
        const canvas = await html2canvas(element);
        return canvas.toDataURL("image/png");
    };

    function download(dataurl: string, filename: string) {
        const link = document.createElement("a");
        link.href = dataurl;
        link.download = filename + '.png';
        link.click();
    };

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        Array.from(props.data).forEach(async (element, index) => {
            const image = await getImage(element);
            const filename = props.name + (props.data.length == 1 ? '' : `_${index+1}`);
            download(image, filename);
        });
    };

    return (
        <a href="" className="round-btn">
            <img src={PictureImg} onClick={handleClick}></img>
        </a>
    )
}

export default ExportPng;