import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useContext } from 'react';

import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import ExcelImg from '../../../img/excel.svg';


const ExportExcel:FunctionComponent = () => {
    const pulseHash = useContext(PulseContext);
    let fileDownload = require('js-file-download');
    
    async function downloadExcel() {
        try {
            const response = await axiosConfig.get(`api/pulses/${pulseHash}/results/export`, {
                responseType: 'blob'
            });

            fileDownload(response.data, `statistics_${Date.now()}.xlsx`);
        } catch {
            showNotification('Server error', true);
        }
    }

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        downloadExcel();
    };

    return (
        <a href="" className="round-btn">
            <img src={ExcelImg} onClick={handleClick}></img>
        </a>
    )
}

export default ExportExcel;