import React, { FunctionComponent, useEffect, useState, useRef } from 'react';

import Dropdown from '../common/Dropdown';
import RadioGroup from '../common/RadioGroup';
import { defaultYesNoOptions } from '../../base/Constants';


export interface Location {
    id: number,
    name: string
}
export interface FinalFormOptions {
    askEmail: number,
    askPhone: number,
    askAge: number,
    askGender: number,
    location: number
}
interface FinalFormSettingsProps {
    settingsOptions: FinalFormOptions,
    locations: Location[],
    onChange: (options: FinalFormOptions) => void
}

const FinalFormSettings: FunctionComponent<FinalFormSettingsProps> = ({settingsOptions, locations, onChange}) => {
    const isInitialMount = useRef(true);
    const [options, setOptions] = useState(settingsOptions);

    useEffect(() => {
        if (isInitialMount.current)
            isInitialMount.current = false;
        else
            onChange(options);
    }, [options])
    
    function onLocationChange(value: string) {
        setOptions(prevState => ({
            ...prevState,
            location: +value
        }));
    }

    return (
        <div className="col-12 col-m-12 col-s-12">
            <RadioGroup 
                className="radio-group col-3 col-m-3 col-s-6 border"
                title="Ask for e-mail" 
                name="askEmail"
                value={options.askEmail} 
                options={defaultYesNoOptions}
                onChange={(value) => setOptions(prevState => ({...prevState, askEmail: value}))} 
            />
            <RadioGroup 
                className="radio-group col-3 col-m-3 col-s-6 border"
                title="Ask for phone no"
                name="askPhone"
                value={options.askPhone}  
                options={defaultYesNoOptions}
                onChange={(value) => setOptions(prevState => ({...prevState, askPhone: value}))} 
            />
            <RadioGroup
                className="radio-group col-3 col-m-3 col-s-6 border"                       
                title="Ask for age"
                name="askAge" 
                value={options.askAge} 
                options={defaultYesNoOptions}
                onChange={(value) => setOptions(prevState => ({...prevState, askAge: value}))} 
            />
            <RadioGroup 
                className="radio-group col-3 col-m-3 col-s-6"
                title="Ask for gender" 
                name="askGender"
                value={options.askGender} 
                options={defaultYesNoOptions}
                onChange={(value) => setOptions(prevState => ({...prevState, askGender: value}))} 
            />
            <div className="dropdown-wrapper col-5 col-m-7 col-s-12 aligned-center">
                <Dropdown 
                    label="" 
                    defaultVal={'0'}
                    value={options.location}
                    options={locations.map((item) => {return {label: item.name, value: item.id}})} 
                    onChange={onLocationChange} />
            </div>
        </div>
    )
}

export default FinalFormSettings;