import React, { FunctionComponent } from "react";

import { Option, RowOption } from './Option';

import QuestionImg1 from '../../../img/question1.svg';
import QuestionImg2 from '../../../img/question2.svg';

export interface QuestionProps {
    index?: number,
    id: number,
    text: string,
    responses: number,
    options: number,
    responses_data: []
}

export const Question: FunctionComponent<QuestionProps> = (props) => {
    return (
        <div className="question"> 
            <div className="header col-12 col-m-12 col-s-12 aligned-left">
                <img src={QuestionImg2}></img>
                <p>{props.text}</p>
            </div>
            <div className="options col-12 col-m-12 col-s-12">
                {props.responses_data.map((option: [string, number, number], optionIndx) => {
                    return <Option
                        index={String.fromCharCode(optionIndx + 65)}
                        text={option[0]}
                        responseVal={option[1]}
                        predictionVal={option[2]}
                    />
                })}
            </div>
            <div className="responded-stats col-12 col-m-12 col-s-12">
                Responded:&nbsp;&nbsp;
                <b>{props.responses}</b>
            </div>
        </div>
    )
}

export const RowQuestion: FunctionComponent<QuestionProps> = (props) => {
    return (
        <tr id={props.id.toString()} className="question-row col-12 col-m-12 col-s-12 aligned-between">
            <td>
                <div className="question-img col-12 aligned-center">
                    <img src={QuestionImg1}></img>
                </div>
            </td>
            <td>
                <table className="col-12 col-m-12 col-s-12">
                    <tbody>
                        <tr className="question-details col-12 col-m-12 col-s-12">
                            <td className="question-index col-1 col-m-1 col-s-12 aligned-left">Question {props.index}</td>
                            <td className="question-text col-7 col-m-7 col-s-12 aligned-left">{props.text}</td>
                            <td className="responses col-4 col-m-4 col-s-12 aligned-right">Responses: {props.responses}</td>
                        </tr>
                        {props.responses_data.map((option: [string, number, number], option_indx: number) => {
                            return(
                                <RowOption 
                                    key={option_indx} 
                                    index={String.fromCharCode(option_indx + 65)} 
                                    text={option[0]} 
                                    responseVal={option[1]} 
                                    predictionVal={option[2]} 
                                />
                            )
                        })}
                   </tbody>
                </table>
            </td>
        </tr>
    )
}
