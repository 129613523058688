import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ExportExcel from '../buttons/ExportExcel';
import ExportPng from '../buttons/ExportPng';
import { MainContent, MainContentHeader, Sidebar, Page } from '../../base/PageCommon';
import Preloader from '../../base/Preloader';
import { QuestionProps } from './Question';
import Respondents from './Respondents';
import RouteNotFound from '../../base/RouteNotFound';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';
import Table from '../common/Table';


const Results: FunctionComponent = () => {
    const { hash } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const [tableProps, setTableProps] = useState({page: 0, perPage: 5});
    const [questionsCnt, setQuestionsCnt] = useState(0);
    const [questionsData, setQuestionsData] = useState<QuestionProps[]>([]);
    const [pngData, setPngData] = useState<HTMLCollectionOf<HTMLElement>>();

    useEffect(() => {
        getQuestions();
    }, [tableProps]);

    async function getQuestions() {
        interface ResponseData {
            cnt: number, 
            questions: QuestionProps[]
        }
        try {
            const response = await axiosConfig.get<ResponseData>(`api/pulses/${hash}/questions/results`, {
                params: {
                    page: tableProps.page,
                    perPage: tableProps.perPage
                }
            });

            setQuestionsCnt(response.data.cnt);
            setQuestionsData(response.data.questions);
            
            const questionsElems = document.getElementsByClassName('question-row') as HTMLCollectionOf<HTMLElement>;
            setPngData(questionsElems);

            setIsLoading(false);
        } catch {
            showNotification('Server error', true);
        }
    }

    const onQuestionsTableChange = (page: number, perPage: number) => {
        setIsLoading(true);
        setTableProps({page: page, perPage: perPage});
    };
    
    return ( hash ? (
            <PulseContext.Provider value={hash}>
                <Page pageId="results">
                    <Sidebar >
                        <Respondents />
                    </Sidebar >

                    <MainContent>
                        <MainContentHeader>
                            <div className="aligned-right">Description</div>
                            <div className="aligned-right header-actions">
                                <ExportExcel />

                                { pngData && (
                                    <ExportPng name={`statistics_${Date.now()}`} data={pngData} />
                                )}
                            </div>
                        </MainContentHeader>

                        {isLoading ? (
                            <Preloader />
                        ) : (
                            <div id="questions" className="questions-list col-12 col-m-12 col-s-12">
                                <Table 
                                    title="Questions"
                                    data={questionsData} 
                                    page={tableProps.page} 
                                    perPage={tableProps.perPage} 
                                    rowsCnt={questionsCnt} 
                                    onChange={onQuestionsTableChange}
                                />
                            </div>
                        )}
                    </MainContent >
                </Page>
            </PulseContext.Provider>
    ) : (
        <RouteNotFound/>
    ))
};

export default Results;