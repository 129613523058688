import axiosConfig from '../../../axiosConfig';
import React, { FunctionComponent, useState, useEffect, useContext } from 'react';

import { MainContent, MainContentHeader } from '../../base/PageCommon';
import Questions from './Questions';
import { QuestionI } from './Utils';
import Preloader  from '../../base/Preloader';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';
import Graph from './Graph';

import AdvanceEditorImg from '../../../img/advance_editor.svg';
import SimpleEditorImg from '../../../img/simple_editor.svg';


const SIMPLE_EDITOR = 1;
const ADVANCE_EDITOR = 2;

const QuestionsAnswers: FunctionComponent = () => {
    const pulseHash = useContext(PulseContext);

    const [isLoading, setIsLoading] = useState(true);
    const [editorType, setEditorType] = useState(SIMPLE_EDITOR);
    const [questions, setQuestions] = useState<QuestionI[]>([]);


    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        interface ResponseData {
            questions: QuestionI[]
        }
        try {
            const response = await axiosConfig.get<ResponseData>(`api/pulses/${pulseHash}/questions`);

            setQuestions(response.data.questions);
            setIsLoading(false);
        } catch {
            showNotification('Server error', true);
        }
    }

    function refresh() {
        setIsLoading(true);
        getData();
    }

    function onEditorChange() {
        refresh();

        const newEditorType = editorType == ADVANCE_EDITOR ? SIMPLE_EDITOR : ADVANCE_EDITOR;
        setEditorType(newEditorType);
    }

    return (
        <MainContent>
            <MainContentHeader>
                <div className="aligned-right">Questions and answers</div>
                <div className="aligned-right header-actions">
                    <button className="round-btn">
                        {editorType == SIMPLE_EDITOR ? (
                            <img src={SimpleEditorImg} onClick={onEditorChange}></img>
                        ) : (
                            <img src={AdvanceEditorImg} onClick={onEditorChange}></img>
                        )}
                    </button>
                </div>
            </MainContentHeader>

            {isLoading ? (
                <Preloader />
            ) : (
                editorType == SIMPLE_EDITOR ? (
                    <div id="editor-simple" className="col-12 col-m-12 col-s-12">
                        <Questions _questions={questions} />
                    </div>
                ) : (
                    <div id="editor-advance" className="col-12 col-m-12 col-s-12">
                        <Graph questions={questions} refresh={refresh}/>
                    </div>
                )
            )}
        </MainContent>
    );
}

export default QuestionsAnswers;