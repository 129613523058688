import axiosConfig from '../../../axiosConfig';
import debounce from 'lodash.debounce';
import React, { FunctionComponent, useEffect, useState, useCallback, useRef, useContext } from 'react';

import { MainContent, MainContentHeader } from '../../base/PageCommon';
import Preloader from '../../base/Preloader';
import { PulseContext } from '../../base/Context';
import { showNotification } from '../../../App';

import InternalNameImg from '../../../img/internal_name.svg';
import PublicNameImg from '../../../img/public_name.svg';
import UploadImg from '../../../img/upload_image.svg';
import TextImg from '../../../img/description2.svg';
import TitleImg from '../../../img/title.svg';


interface MetaDataProps {
    title: string,
    description: string
}
interface PulseInfoProps {
    publicName: string,
    internalName: string
}
interface PulseImageProps {
    imagePreviewUrl: string
}


const MetaData:FunctionComponent<MetaDataProps> = (props) => {
    const pulseHash = useContext(PulseContext);
    const isInitialMount = useRef(true);

    const [title, setTitle] = useState(props.title);
    const [description, setDescription] = useState(props.description);

    const debouncedSave = useCallback(
        debounce(async (newTitle: string, newDescription: string) => {
          await saveMetadata(newTitle, newDescription);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            debouncedSave(title, description);
        }
    }, [title, description, debouncedSave])

    async function saveMetadata(newTitle: string, newDescription: string) {
        try {
            await axiosConfig.put(`api/pulses/${pulseHash}/metadata`, {
                title: newTitle,
                description: newDescription,
            });

            showNotification('Saved');
        } catch {
            showNotification('Server error', true);
        }
    }

    return (
        <div className="additional-info col-12 col-m-12 col-s-12">
            <div className="col-12 col-m-12 col-s-12 aligned-between">
                <img src={TitleImg}></img>
                <input 
                    id="pulse-title" 
                    className="col-11 col-m-11 col-s-11" 
                    type="text" 
                    placeholder="Title..."
                    value={title}
                    onChange={(e) => {setTitle(e.target.value)}}> 
                </input>
            </div>
            <div className="col-12 col-m-12 col-s-12 aligned-between aligned-items-start">
                <img src={TextImg}></img>
                <textarea 
                    id="description" 
                    className="col-11 col-m-11 col-s-11" 
                    placeholder="Description..."
                    value={description}
                    onChange={(e) => {setDescription(e.target.value)}}>
                </textarea>
            </div>
        </div>
    )
}

const PulseInfo:FunctionComponent<PulseInfoProps> = (props) => {
    const pulseHash = useContext(PulseContext);
    const isInitialMount = useRef(true);

    const [publicName, setPublicName] = useState(props.publicName);
    const [internalName, setInternalName] = useState(props.internalName);

    const debouncedSave = useCallback(
        debounce(async (newPublicName: string, newInternalName: string) => {
            await saveInfo(newPublicName, newInternalName);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            debouncedSave(publicName, internalName);
        }
    }, [publicName, internalName, debouncedSave])

    function saveInfo(newPublicName: string, newInternalName: string) {
        try {
            axiosConfig.patch(`api/pulses/${pulseHash}`, {
                    name: newPublicName,
                    internal_name: newInternalName,
            });

        } catch {
            showNotification('Server error', true);
        }
    }

    return (
        <div className="main-info col-12 col-m-12 col-s-12">
            <div className="col-12 col-m-12 col-s-12 aligned-between">
                <img src={PublicNameImg}></img>
                <input 
                    id="public-name" 
                    className="col-11 col-m-11 col-s-11" 
                    type="text" 
                    placeholder="Public name..."
                    value={publicName}
                    onChange={(e) => setPublicName(e.target.value)}>
                </input>
            </div>
            <div className="col-12 col-m-12 col-s-12 aligned-between">
                <img src={InternalNameImg}></img>
                <input 
                    id="internal-name" 
                    className="col-11 col-m-11 col-s-11" 
                    type="text" 
                    placeholder="Internal name..."
                    value={internalName}    
                    onChange={(e) => setInternalName(e.target.value)}>
                </input>
            </div>
        </div>
    )
}

const PulseImage: FunctionComponent<PulseImageProps> = (props) => {
    const pulseHash = useContext(PulseContext);

    const [imagePreviewUrl, setImagePreviewUrl] = useState(props.imagePreviewUrl)
    const [image, setImage] = useState<File>();
    
    useEffect(() => {
        if (image)
            saveImage(image);
    }, [image]);

    async function readAsDataURL(file: File) {
        return new Promise((resolve)=>{
			let fileReader = new FileReader();
			fileReader.onload = function(){
				return resolve({
                    data: fileReader.result,
                    name: file.name,
                    size: file.size,
                    type: file.type
                });
			}
			fileReader.readAsDataURL(file);
		})
    }

    async function saveImage(newImage: File) {
        try {
            const response = await axiosConfig.put(`api/pulses/${pulseHash}/metadata`, {
                    image: await readAsDataURL(newImage)
                }); 

            setImagePreviewUrl(response.data.imageLink);
            showNotification('Saved')
        } catch {
            showNotification('Server error', true);
        }
    }

    function onUploadImage(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (files && files.length > 0)
            setImage(files[0]);
    }

    return (
        <div className="pulse-image col-6 col-m-6 col-s-12">
            <img src={imagePreviewUrl} className="image-preview"></img>
            <div className="aligned-center">
                <label htmlFor="files" className="btn round-btn col-12 col-m-12 col-s-12 aligned-center">
                    <img src={UploadImg}></img>
                    <span className="col-12 col-m-12 col-s-12 aligned-center">Upload you image</span>
                </label>
                <input id="files" type="file" onChange={onUploadImage}></input>
            </div>
        </div>
    )
}

const DescriptionPage:FunctionComponent = () => {
    const pulseHash = useContext(PulseContext);
    const [isLoading, setIsLoading] = useState(true);

    const [pulseInfo, setPulseInfo] = useState({
        publicName: '',
        internalName: ''
    })
    const [metadata, setMetadata] = useState({
        title: '',
        description: '',
        imagePreview: ''
    });

    useEffect(() => {
        const getData = async () => {
            await Promise.all([getPulse(), getMetadata()]);
            setIsLoading(false);
        }

        getData();
    }, []);

    async function getPulse() {
        try {   
            const response = await axiosConfig.get(`api/pulses/${pulseHash}`);

            setPulseInfo({
                publicName: response.data.publicName,
                internalName: response.data.internalName
            });
        } catch {
            showNotification('Server error', true);
        }
    }

    async function getMetadata() {
        try {   
            const response = await axiosConfig.get(`api/pulses/${pulseHash}/metadata`);

            setMetadata({
                title: response.data.title,
                description: response.data.description,
                imagePreview: response.data.image
            });
        } catch {
            showNotification('Server error', true);
        }
    }

    return (
        <MainContent>
            <MainContentHeader>
                Description
            </MainContentHeader>

            {isLoading ? (
                <Preloader />
            ) : (
                <div id="pulse-description" className="container col-12 col-m-12 col-s-12">
                    <div className="container-inner col-12 col-m-12 col-s-12">
                        <div className="pulse-info col-6 col-m-6 col-s-12">
                            <PulseInfo 
                                publicName={pulseInfo.publicName}
                                internalName={pulseInfo.internalName}
                            />

                            <MetaData 
                                title={metadata.title}
                                description={metadata.description}
                            />
                        </div>

                        <PulseImage imagePreviewUrl={metadata.imagePreview}/>
                    </div>
                </div>
            )}
        </MainContent>
    );
}

export default DescriptionPage;