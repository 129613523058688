import React, { FunctionComponent } from 'react';

import { showNotification } from '../../../App';

import LinkImg from '../../../img/link.svg';

interface CopyLinkProps {
    link: string
}

const CopyLink: FunctionComponent<CopyLinkProps> = ({link}) => {
    function copyToClipboard(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        const tempInput = document.createElement('input');
        document.body.appendChild(tempInput);
        tempInput.value = link;
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();

        showNotification('Anonymous link is copied.');
    }

    return (
        <a href={link} className='copy-link round-btn'>
            <img src={LinkImg} onClick={copyToClipboard}/>
        </a>
    )
}

export default CopyLink;
